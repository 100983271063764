import { graphqlClient } from '@/services/apollo/apollo';
import { gql } from '@apollo/client/core'

export const newsActions = {
    async news({ commit }: any) {
        const response = await graphqlClient.query({
            fetchPolicy: "network-only",
            query: gql`
          query News {
            news{
                uuid
                title
                excerpt
                content
                author        
                featuredMediaUrl
                externalLink
                categories{
                    code
                    name
                }
                club{
                    name
                    featuredMediaFormat
                    branding{
                        logoUrl
                    }
                }
                sport
                mediaChannel
                postCreatedAt
                postUpdatedAt
            }
          }
        `,
        });

        // Trigger a Mutation
        commit('setNews', response.data.news);
    },
}