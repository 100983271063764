import { ApolloClient, createHttpLink, ApolloCache, ApolloLink } from '@apollo/client/core'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { setContext } from '@apollo/client/link/context';
import { errorLink } from './onError';

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('accessToken');
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "unauthenticated",
    }
  }
});

export const graphqlClient = new ApolloClient({
  link: errorLink.concat(authLink).concat(httpLink),
  cache: new InMemoryCache({
    dataIdFromObject: (object: any) => object.uuid,
  }) as any,
  connectToDevTools: true
});