
import NewsList from "./components/NewsList.vue";
import { defineComponent } from 'vue';
import { useStore } from "vuex";
export default defineComponent({
  components: {
    NewsList
  },
  setup() {
    const store = useStore();
    store.dispatch("news");
  }
});
