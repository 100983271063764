import { News } from "@/types/News/News";

export const newsGetters = {
    article(state: any) {
        return (articleUuid: string) => {
            return state.news.find((article: News) => article.uuid === articleUuid);
        };
    },
    news(state: any) {
        return state.news;
    }
}