
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex'
import { IonGrid, IonRow, IonCol } from "@ionic/vue";

import NewsCard from "./NewsCard.vue"

export default defineComponent({
    components: {
        IonCol,
        IonGrid,
        IonRow,
        NewsCard,
    },

    setup() {
        const store = useStore();
        const news = computed(() => store.getters.news);

        return {
            news
        }
    }
});
