import {
    PushNotificationSchema,
    PushNotifications,
    Token,
    ActionPerformed,
} from '@capacitor/push-notifications';
import { CreateAccessTokenFunctionType, GetAccessTokenCallbackFunctionType } from '../auth/getAccessToken';

const FirebaseService = (device: string, callback: GetAccessTokenCallbackFunctionType, createAccessToken: CreateAccessTokenFunctionType) => {

    console.log('Initializing FIREBASE');

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
        } else {
            // Show some error
        }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
        (token: Token) => {
            //alert('Push registration success, token: ' + token.value);
            console.log(token.value)
            createAccessToken(device, token.value, callback)
        }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
        (error: any) => {
            alert('Error on registration: ' + JSON.stringify(error));
        }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
            alert('Push received: ' + JSON.stringify(notification));
        }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
            alert('Push action performed: ' + JSON.stringify(notification));
        }
    );
}
export default FirebaseService