import { onError } from "@apollo/client/link/error";

// Log any GraphQL errors or network error that occurred
export const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.map(({ message, extensions, locations, path }) => {

            if (extensions && extensions.code && extensions.code === "DEVICE_NOT_FOUND") {
                alert("Dein Gerät konnte in unserer Datenbank nicht gefunden werden. Daher wird deine Sitzung jetzt "
                    + " zurückgesetzt. Möchtest Du Deine alten Subscriptions wiederherstellen, wende Dich bitte an den Support.")
                localStorage.removeItem('accessToken')
                window.location.reload()

            }
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        }
        );
    if (networkError) console.log(`[Network error]: ${networkError}`);
});