
import { defineComponent } from 'vue';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonBackButton,
} from "@ionic/vue";

export default defineComponent({
  props: {
    pageTitle: String,
    pageDefaultBackLink: String
  },
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
    IonBackButton,
  },
  setup() {
    return {}
  }
})
