
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent
} from "@ionic/vue";
import { defineComponent } from 'vue';
export default defineComponent({
    props: ["pageTitle"],
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
    },
    setup() {
        return {};
    },
});
