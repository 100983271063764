import { gql } from '@apollo/client/core'
import { graphqlClient } from "../../../services/apollo/apollo"
import createClubSubscription from './actions/createClubSubscription';
import deleteClubSubscription from './actions/deleteClubSubscription';

export const clubSubscriptionActions = {
  ...createClubSubscription,
  ...deleteClubSubscription,
  async clubSubscriptions({ commit }: any) {
    const response = await graphqlClient.query({
      fetchPolicy: "network-only",
      query: gql`
          query ClubSubscriptions {
            clubSubscriptions {
                uuid
                clubUuid
            }
          }
        `,
    });

    // Trigger a Mutation
    commit('setClubSubscriptions', response.data.clubSubscriptions);
  },
}