<template>
  <ion-card class="ios hydrated" v-if="article && club">
    <router-link :to="`/news/${article.uuid}`">
      <div
        class="imageholder"
        v-bind:class="{
          'img-1-1': article.club.featuredMediaFormat == '1:1',
          'img-16-9': article.club.featuredMediaFormat == '16:9',
          'img-3-2': article.club.featuredMediaFormat == '3:2',
          'img-4-3': article.club.featuredMediaFormat == '4:3'
        }"
        v-bind:style="{ backgroundImage: `url(${cardImage})` }"
      >
        <div class="cal-box">
          <h2>{{ date.day }}</h2>
          <ion-text>{{ date.month }}</ion-text>
        </div>
        <div class="img-title">
          <ion-item lines="none" color="none" class="title-gradient">
            <ion-icon v-if="article.mediaChannel == 'website'" size="large" :icon="newspaper"></ion-icon>
            <ion-icon v-if="article.mediaChannel == 'twitter'" size="large" :icon="logoTwitter"></ion-icon>
            <ion-icon v-if="article.mediaChannel == 'facebook'" size="large" :icon="logoFacebook"></ion-icon>
            <ion-icon v-if="article.mediaChannel == 'instagram'" size="large" :icon="logoInstagram"></ion-icon>
            <ion-text>
              <p class="ion-text-center">{{ club.name }}</p>
            </ion-text>
            <ion-thumbnail slot="end">
              <img :src="club.branding.logoUrl" />
            </ion-thumbnail>
          </ion-item>
        </div>
      </div>
    </router-link>
    <ion-card-header class="ion-inherit-color ios hydrated">
      <ion-card-title role="heading" class="ion-inherit-color ios hydrated" v-html="article.title"></ion-card-title>
    </ion-card-header>
    <ion-card-content class="ios card-content-ios hydrated">
      <ion-text class="someLines" v-html="article.excerpt"></ion-text>
    </ion-card-content>
    <ion-item lines="none" class="card-footer">
      <ion-icon size="small" :icon="heartOutline" slot="start"></ion-icon>
      <!--             <ion-icon 
                size="small"  
                :icon="notifications" 
                slot="start"
      ></ion-icon>-->
      <ion-icon size="small" :icon="shareSocial" slot="start"></ion-icon>
      <ion-button
        v-if="isMore"
        fill="clear"
        slot="end"
        :router-link="`/news/${article.uuid}`"
      >Mehr lesen</ion-button>
    </ion-item>
  </ion-card>
</template>

<script lang="ts">
import {
  IonCard,
  IonItem,
  IonIcon,
  IonText,
  IonCardTitle,
  IonThumbnail,
  IonCardHeader,
  IonCardContent,
  IonButton,
} from "@ionic/vue";
import {
  logoTwitter,
  logoFacebook,
  logoInstagram,
  newspaper,
  heartOutline,
  heart,
  notifications,
  shareSocial,
} from "ionicons/icons";

import { News } from "@/types/News/News";

import { defineComponent, computed, PropType } from 'vue';

export default defineComponent({
  name: "NewsCard",
  props: {
    article: {
      type: Object as PropType<News>,
      required: true
    },
  },
  components: {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonIcon,
    IonItem,
    IonText,
    IonThumbnail,
  },
  setup(props) {

    const date: any = computed(() => {
      return {
        day: new Date(props.article.postCreatedAt).toLocaleDateString("de-DE", { day: "2-digit" }),
        month: new Date(props.article.postCreatedAt).toLocaleDateString("de-DE", { month: "short" })
      };
    });

    const isMore = computed(() => {
      return props.article?.content ? true : false;
    });

    const club = computed(() => {
      return props.article?.club
    });

    const cardImage = computed(() => {
      return (props.article?.featuredMediaUrl != '' && props.article?.featuredMediaUrl != null) ? props.article?.featuredMediaUrl : club.value.branding.logoUrl
    })

    return {
      logoTwitter,
      logoFacebook,
      logoInstagram,
      newspaper,
      heartOutline,
      heart,
      notifications,
      shareSocial,
      date,
      isMore,
      club,
      cardImage
    };
  }
});
</script>

<style scoped>
ion-card-title {
  font-family: var(--ion-font-family-light);
  color: var(--ion-color-dark);
}

ion-icon {
  color: var(--ion-color-primary);
}

ion-item.title-gradient > ion-icon {
  color: var(--ion-color-primary-contrast);
  margin-right: 8px;
}

ion-col > ion-card-subtitle,
ion-item > ion-card-subtitle {
  color: var(--ion-color-primary-contrast);
  font-family: var(--ion-font-family-semi-bold);
  line-height: 100%;
}

ion-col > ion-thumbnail,
ion-item > ion-thumbnail {
  --border-radius: 25%;
  border: 1px var(--ion-color-primary) solid;
  width: 2rem;
  height: 2rem;
  background: var(--ion-color-primary-contrast);
}

ion-col > ion-thumbnail > img,
ion-item > ion-thumbnail > img {
  width: calc(2rem - 4px);
  height: calc(2rem - 4px);
  margin: 1px;
}

ion-button {
  color: var(--ion-color-primary);
}

ion-card {
  border-radius: 0.5rem;
}

ion-card-content {
  color: var(--ion-color-dark);
}

.card-text {
  font-family: var(--ion-font-family);
  font-size: 20px;
  color: var(--ion-color-healy-darkblue);
}

.img-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  /*     left: 2rem;
 */
}

.img-title ion-text {
  margin-left: auto;
  margin-right: auto;
  color: var(--ion-color-primary-contrast);
  font-family: var(--ion-font-family-semi-bold);
}

.cal-box {
  position: absolute;
  top: 1rem;
  left: 1rem;
  border: var(--ion-color-primary-contrast) solid 1px;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  border-radius: 0.5rem;
  padding: 0;
  height: 3rem;
  width: 3rem;
  text-align: center;
  font-size: 14px;
}

.cal-box > h2 {
  margin-top: 0.125rem;
  margin-bottom: -0.125rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.cal-box > ion-text {
  font-family: var(--ion-font-family-medium);
  font-size: 0.75rem;
  line-height: 100%;
}

.imageholder {
  position: relative;
  width: 100%;
  padding-bottom: 56.75%;
  background-size: cover;
  background-position: center center;
}

.img-1-1 {
  padding-bottom: 100%;
}

.img-16-9 {
  padding-bottom: 56.75%;
}

.img-3-2 {
  padding-bottom: 66.6667%;
}

.img-4-3 {
  padding-bottom: 75%;
}

.title-gradient {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.75) 0%,
    rgba(0, 0, 0, 0.5) 60%,
    rgba(0, 0, 0, 0) 100%
  );
}

.someLines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin-bottom: 48px;
}

.card-footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
</style>