import { createRouter, createWebHistory } from '@ionic/vue-router';
// import MemoriesPage from '../views/MemoriesPage.vue';
import NewsPage from '../views/News/List/NewsPage.vue'

const routes = [
  {
    path: '',
    redirect: '/news'
  },
  {
    path: '/news',
    component: NewsPage
  },
  {
    path: '/news/:uuid',
    component: () => import('../views/News/Detail/NewsDetailsPage.vue')
  },
  {
    path: '/standings',
    component: () => import('../views/Standings/StandingsPage.vue')
  },
  {
    path: '/clubs',
    component: () => import('../views/Club/List/ClubsPage.vue')
  },
  {
    path: '/clubs/:uuid',
    component: () => import('../views/Club/Detail/ClubPage.vue')
  },
  {
    path: '/settings',
    component: () => import('../views/Settings/SettingsPage.vue')
  },
  {
    path: '/welcome',
    component: () => import('../views/WelcomePage.vue')
  },

  /**
   * BACKUPS #################################################################
   */

  {
    path: '/websites',
    component: () => import('../views/_backups/WebsitesPage.vue')
  },
  {
    path: '/cards',
    component: () => import('../views/_backups/CardsPage.vue')
  }, {
    path: '/tweets',
    component: () => import('../views/_backups/TweetsPage.vue')
  }, {
    path: '/facebook',
    component: () => import('../views/_backups/FacebookPage.vue')
  }, {
    path: '/instagram',
    component: () => import('../views/_backups/InstagramPage.vue')
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
