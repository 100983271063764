import { Club } from "@/types/Club/Club";
import { ClubSubscription } from "@/types/Club/ClubSubscription/ClubSubscription";

export const clubSubscriptionGetters = {
    clubSubscriptions(state: any) : ClubSubscription[]{
        return state.clubSubscriptions;
    },
    isClubSubscribed(state: any) {
        return (club: Club) => {
            if (state.clubSubscriptions.findIndex((element: ClubSubscription) => element.clubUuid === club.uuid) === -1) {
                return false;
            } else {
                return true;
            }
        };
    }
}