import { Club } from '@/types/Club/Club';
import { gql } from '@apollo/client/core'
import { graphqlClient } from "../../../../services/apollo/apollo"

export default {
  async createClubSubscription({ commit }: any, club: Club) {
    const response = graphqlClient.mutate({
      mutation: gql`
        mutation CreateClubSubscription($clubUuid: String!) {
          club(uuid: $clubUuid) {
            createSubscription {
              uuid
            }            
          }          
        }
      `,
      variables: {
        clubUuid: club.uuid
      }
    });
    commit('createSubscription', club);
  },
}