import axios from "axios";
import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';
import FirebaseService from "../firebase/FirebaseService";

export type GetAccessTokenCallbackFunctionType = () => void
export type CreateAccessTokenFunctionType = (device: string, firebaseToken: string, callback: GetAccessTokenCallbackFunctionType) => void

export const getAccessToken = async (callback: any) => {

    const accessToken = localStorage.getItem('accessToken');

    if (accessToken === null) {
        console.log("Token not existing in local storage: initialize Session...")

        const deviceInfo = await Device.getInfo();
        const deviceId = await Device.getId();
        const device = deviceId.uuid + '-' + deviceInfo.model;

        if (Capacitor.isPluginAvailable('PushNotifications')) {
            FirebaseService(device, callback, createAccesstoken)
        } else {
            createAccesstoken(device, "", callback)
        }

    } else {
        callback()
    }
};

const createAccesstoken = async (device: string, firebaseToken: string, callback: GetAccessTokenCallbackFunctionType) => {
    axios.post(`${process.env.VUE_APP_REST_URL}/sessions`, {
        device: device,
        firebaseToken: firebaseToken
    })
        .then(function (response) {
            // handle success
            localStorage.setItem('accessToken', response.data.token);
            localStorage.setItem('guestName', response.data.guestName);
            callback()
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        });
}