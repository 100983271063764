import { gql } from '@apollo/client/core'
import { graphqlClient } from "../../services/apollo/apollo"

export const clubActions = {
  async clubs({ commit }: any) {
    const response = await graphqlClient.query({
      query: gql`
        query Clubs {
          clubs {
              uuid
              name
              featuredMediaFormat
              sport
              address{
                city
                zipCode                
              }
              branding{
                logoUrl
              } 
              mediaChannel {
                hasWordpress
                hasTwitter
                hasFacebook
                hasInstagram
              }             
          }
        }
      `,
    });

    // Trigger a Mutation
    commit('setClubs', response.data.clubs);
  },
}
