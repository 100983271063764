import { createStore } from "vuex";

import { Club } from "@/types/Club/Club";
import { News } from "@/types/News/News";
import { ClubSubscription } from "@/types/Club/ClubSubscription/ClubSubscription";

import { clubGetters } from "./club/getters";
import { clubMutations } from "./club/mutations";
import { clubActions } from "./club/actions";
import { clubSubscriptionMutations } from "./club/subscriptions/mutations";
import { clubSubscriptionActions } from "./club/subscriptions/actions";
import { clubSubscriptionGetters } from "./club/subscriptions/getters";
import { newsMutations } from "./news/mutations";
import { newsActions } from "./news/actions";
import { newsGetters } from "./news/getters";

const store = createStore({
    state: {
        news: <News[]>[],
        clubs: <Club[]>[],
        clubSubscriptions: <ClubSubscription[]>[]
    },
    getters: {
        ...newsGetters,
        ...clubGetters,
        ...clubSubscriptionGetters,
    },
    mutations: {
        ...newsMutations,
        ...clubMutations,
        ...clubSubscriptionMutations

    },
    actions: {
        ...newsActions,
        ...clubActions,
        ...clubSubscriptionActions
    }
});


export default store;

export type State = typeof store;
