import { v4 as uuidv4 } from "uuid";

import { Club } from "@/types/Club/Club";
import { ClubSubscription } from "@/types/Club/ClubSubscription/ClubSubscription";

export const clubSubscriptionMutations = {
    setClubSubscriptions(state: any, clubSubscriptions: ClubSubscription[]): any {
        state.clubSubscriptions = clubSubscriptions
    },
    createSubscription(state: any, club: Club): any {
        if (state.clubSubscriptions.findIndex((element: ClubSubscription) => element.clubUuid === club.uuid) === -1) {
            const randomUuid = uuidv4();
            const newSubscription = {
                uuid: randomUuid,
                clubUuid: club.uuid
            }
            state.clubSubscriptions.push(newSubscription);
        }
    },
    deleteSubscription(state: any, club: Club): any {
        if (state.clubSubscriptions.findIndex((element: ClubSubscription) => element.clubUuid === club.uuid) != -1) {
            state.clubSubscriptions.splice(state.clubSubscriptions.findIndex((element: ClubSubscription) => element.clubUuid === club.uuid), 1);
        }
    }
}