<template>
  <IonApp v-if="!loading">
    <IonSplitPane content-id="main-content">
      <ion-menu content-id="main-content" type="overlay">
        <ion-content>
          <ion-list id="mainmenu-list">
            <ion-list-header>ClubNews</ion-list-header>
            <ion-note>{{ user }}</ion-note>

            <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
              <ion-item
                router-direction="root"
                :router-link="p.url"
                lines="none"
                detail="false"
                class="hydrated"
                :class="{ selected: currentRoute === p.url }"
              >
                <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
          <!--
          <ion-list id="playground-list">
            <ion-list-header>Playground Pages</ion-list-header>
            <ion-note>(in Entwicklung)</ion-note>

            <ion-menu-toggle auto-hide="false" v-for="(p, i) in playgroundPages" :key="i">
              <ion-item @click="selectedIndex = i + 10" router-direction="root" :router-link="p.url" lines="none" detail="false" class="hydrated" :class="{ selected: selectedIndex === i + 10 }">
                <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>

          </ion-list>

           <ion-list id="labels-list">
            <ion-list-header>Labels</ion-list-header>

            <ion-item v-for="(label, index) in labels" lines="none" :key="index">
              <ion-icon slot="start" :ios="bookmarkOutline" :md="bookmarkSharp"></ion-icon>
              <ion-label>{{ label }}</ion-label>
            </ion-item>
          </ion-list>-->
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </IonSplitPane>
  </IonApp>
  <div class="loading-spinner" v-else>Receiving access token...</div>
</template>

<script lang="ts">
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,
  useIonRouter,
  alertController
} from "@ionic/vue";

import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { App } from '@capacitor/app';
 
import {
  archiveOutline,
  archiveSharp,
  bookmarkOutline,
  bookmarkSharp,
  heartOutline,
  heartSharp,
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  trashOutline,
  trashSharp,
  warningOutline,
  warningSharp,
  newspaper,
  logoTwitter,
  logoFacebook,
  logoInstagram,
  settings,
  basketball,
  megaphone,
  statsChart,
  home,
} from "ionicons/icons";
import { appPages } from "../config/menu/appPages";
import { playgroundPages } from "../config/menu/playgroundPages";
import { getAccessToken } from "./services/auth/getAccessToken";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane
  },
  data() {
    return {
      user: "unverified",
      loading: true,
    };
  },

  computed: {
    currentRoute() {
      return this.$route.path;
    },
  },

  methods: {
    authenticated() {
      this.loading = false;
      this.user = `${localStorage.getItem("guestName")}@clubnews.de`;
    },
  },

  mounted() {
    getAccessToken(this.authenticated);
  },

  setup() {
    const selectedIndex = ref(0);
    const router = useRouter();
    const ionRouter = useIonRouter();

    const presentAlertCloseApp = async () => {
        const alert = await alertController.create({
            header: 'Achtung',
            //subHeader: 'App schließen?',
            message: 'Diese Aktion schließt die App. Möchtest Du wirklich die App schließen?',
            buttons: [{
                text: 'Abbrechen'
            },
            {
                text: 'Schließen',
                handler: () => {
                    App.exitApp();
                }
            }],
        });

        return alert.present();
    };

    App.addListener('backButton', () => {
        if (!ionRouter.canGoBack()) {
            presentAlertCloseApp();
        } else {
            router.back;
        }
    });

    return {
      selectedIndex,
      appPages,
      archiveOutline,
      archiveSharp,
      bookmarkOutline,
      bookmarkSharp,
      heartOutline,
      heartSharp,
      mailOutline,
      mailSharp,
      paperPlaneOutline,
      paperPlaneSharp,
      playgroundPages,
      trashOutline,
      trashSharp,
      warningOutline,
      warningSharp,
      newspaper,
      logoTwitter,
      logoFacebook,
      logoInstagram,
      basketball,
      megaphone,
      settings,
      statsChart,
      home,
    };
  },
});
</script>

<style scoped>
ion-item {
  cursor: pointer;
}
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#mainmenu-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#mainmenu-list ion-list-header,
ion-menu.md ion-list#playground-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

.loading-spinner {
  color: #fff;
  background: #777;
  text-align: center;
}
</style>