import { Club } from '@/types/Club/Club';
import { gql } from '@apollo/client/core'
import { graphqlClient } from "../../../../services/apollo/apollo"

export default {
  async deleteClubSubscription({ commit }: any, club: Club) {
    const response = graphqlClient.mutate({
      mutation: gql`
        mutation DeleteClubSubscription($clubUuid: String!) {
          club(uuid: $clubUuid) {
            deleteSubscription
          }          
        }
      `,
      variables: {
        clubUuid: club.uuid
      }
    });
    commit('deleteSubscription', club);
  },
}