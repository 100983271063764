
import {
  IonCard,
  IonItem,
  IonIcon,
  IonText,
  IonCardTitle,
  IonThumbnail,
  IonCardHeader,
  IonCardContent,
  IonButton,
} from "@ionic/vue";
import {
  logoTwitter,
  logoFacebook,
  logoInstagram,
  newspaper,
  heartOutline,
  heart,
  notifications,
  shareSocial,
} from "ionicons/icons";

import { News } from "@/types/News/News";

import { defineComponent, computed, PropType } from 'vue';

export default defineComponent({
  name: "NewsCard",
  props: {
    article: {
      type: Object as PropType<News>,
      required: true
    },
  },
  components: {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonIcon,
    IonItem,
    IonText,
    IonThumbnail,
  },
  setup(props) {

    const date: any = computed(() => {
      return {
        day: new Date(props.article.postCreatedAt).toLocaleDateString("de-DE", { day: "2-digit" }),
        month: new Date(props.article.postCreatedAt).toLocaleDateString("de-DE", { month: "short" })
      };
    });

    const isMore = computed(() => {
      return props.article?.content ? true : false;
    });

    const club = computed(() => {
      return props.article?.club
    });

    const cardImage = computed(() => {
      return (props.article?.featuredMediaUrl != '' && props.article?.featuredMediaUrl != null) ? props.article?.featuredMediaUrl : club.value.branding.logoUrl
    })

    return {
      logoTwitter,
      logoFacebook,
      logoInstagram,
      newspaper,
      heartOutline,
      heart,
      notifications,
      shareSocial,
      date,
      isMore,
      club,
      cardImage
    };
  }
});
