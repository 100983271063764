
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,
  useIonRouter,
  alertController
} from "@ionic/vue";

import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { App } from '@capacitor/app';
 
import {
  archiveOutline,
  archiveSharp,
  bookmarkOutline,
  bookmarkSharp,
  heartOutline,
  heartSharp,
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  trashOutline,
  trashSharp,
  warningOutline,
  warningSharp,
  newspaper,
  logoTwitter,
  logoFacebook,
  logoInstagram,
  settings,
  basketball,
  megaphone,
  statsChart,
  home,
} from "ionicons/icons";
import { appPages } from "../config/menu/appPages";
import { playgroundPages } from "../config/menu/playgroundPages";
import { getAccessToken } from "./services/auth/getAccessToken";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane
  },
  data() {
    return {
      user: "unverified",
      loading: true,
    };
  },

  computed: {
    currentRoute() {
      return this.$route.path;
    },
  },

  methods: {
    authenticated() {
      this.loading = false;
      this.user = `${localStorage.getItem("guestName")}@clubnews.de`;
    },
  },

  mounted() {
    getAccessToken(this.authenticated);
  },

  setup() {
    const selectedIndex = ref(0);
    const router = useRouter();
    const ionRouter = useIonRouter();

    const presentAlertCloseApp = async () => {
        const alert = await alertController.create({
            header: 'Achtung',
            //subHeader: 'App schließen?',
            message: 'Diese Aktion schließt die App. Möchtest Du wirklich die App schließen?',
            buttons: [{
                text: 'Abbrechen'
            },
            {
                text: 'Schließen',
                handler: () => {
                    App.exitApp();
                }
            }],
        });

        return alert.present();
    };

    App.addListener('backButton', () => {
        if (!ionRouter.canGoBack()) {
            presentAlertCloseApp();
        } else {
            router.back;
        }
    });

    return {
      selectedIndex,
      appPages,
      archiveOutline,
      archiveSharp,
      bookmarkOutline,
      bookmarkSharp,
      heartOutline,
      heartSharp,
      mailOutline,
      mailSharp,
      paperPlaneOutline,
      paperPlaneSharp,
      playgroundPages,
      trashOutline,
      trashSharp,
      warningOutline,
      warningSharp,
      newspaper,
      logoTwitter,
      logoFacebook,
      logoInstagram,
      basketball,
      megaphone,
      settings,
      statsChart,
      home,
    };
  },
});
