import {
  warningOutline,
  warningSharp,
  newspaper
} from "ionicons/icons";


export const playgroundPages = [
  /* 
  {
    title: 'Websites',
    url: '/websites',
    iosIcon: home,
    mdIcon: home
  },
  {
    title: 'Tweets',
    url: '/tweets',
    iosIcon: logoTwitter,
    mdIcon: logoTwitter
  },
  {
    title: 'Facebook',
    url: '/facebook',
    iosIcon: logoFacebook,
    mdIcon: logoFacebook
  },
  {
    title: 'Instagram',
    url: '/instagram',
    iosIcon: logoInstagram,
    mdIcon: logoInstagram
  }, */
  {
    title: "News (store-basiert)",
    url: "/news",
    iosIcon: newspaper,
    mdIcon: newspaper,
  },
  {
    title: "Cards",
    url: "/cards",
    iosIcon: warningOutline,
    mdIcon: warningSharp,
  },
];