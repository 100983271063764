<template>
  <base-menu-layout pageTitle="News">
    <news-list></news-list>
  </base-menu-layout>
</template>

<script lang="ts">
import NewsList from "./components/NewsList.vue";
import { defineComponent } from 'vue';
import { useStore } from "vuex";
export default defineComponent({
  components: {
    NewsList
  },
  setup() {
    const store = useStore();
    store.dispatch("news");
  }
});
</script>

<style scoped>
</style>