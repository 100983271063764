import {
    newspaper,
    settings,
    basketball,
    statsChart,
} from "ionicons/icons";

export const appPages = [
    {
        title: "Alle News",
        url: "/news",
        iosIcon: newspaper,
        mdIcon: newspaper,
    },
    {
        title: "Tabellen",
        url: "/standings",
        iosIcon: statsChart,
        mdIcon: statsChart,
    },
    {
        title: "Vereine",
        url: "/clubs",
        iosIcon: basketball,
        mdIcon: basketball,
    },
    {
        title: "Einstellungen",
        url: "/settings",
        iosIcon: settings,
        mdIcon: settings,
    },
]