<template>
    <ion-grid>
        <ion-row>
            <ion-col v-for="article in news" :key="article.uuid" size="12" size-sm="6" size-xl="6">
                <news-card :article="article" class="fullheight"></news-card>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex'
import { IonGrid, IonRow, IonCol } from "@ionic/vue";

import NewsCard from "./NewsCard.vue"

export default defineComponent({
    components: {
        IonCol,
        IonGrid,
        IonRow,
        NewsCard,
    },

    setup() {
        const store = useStore();
        const news = computed(() => store.getters.news);

        return {
            news
        }
    }
});
</script>

<style scoped>
.fullheight {
    height: calc(100% - 20px);
}
</style>