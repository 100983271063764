import { Club } from "@/types/Club/Club";
import { ClubSubscription } from "@/types/Club/ClubSubscription/ClubSubscription";
import { useStore } from "vuex";

export const clubGetters: any = {
    clubs(state: any) :Club[] {

        return state.clubs;
    },
    subscribedClubs(state: any) :Club[] {
        const store = useStore();

        const subIds: string[] = [];
        state.clubSubscriptions.forEach(function(element: ClubSubscription) { 
            subIds.push(element.clubUuid);
        });

        const tempClubs: Club[] = [];
        subIds.forEach(function(subId: string) {
            tempClubs.push(store.getters.club(subId));
        });

        tempClubs.sort(function(a: Club,b: Club): number {
            return a.name.toUpperCase() <= b.name.toUpperCase() ? -1 : 1;
        });

        return tempClubs;
    },
    club(state: any) {
        return (clubUuid: string) => {
            return state.clubs.find((club: Club) => club.uuid === clubUuid);

        };
    },
}